@import "../../layout/_vars";
@import "../../layout/_mixins";

.sectionWrapper {
    font-family: "Kanit", "Open Sans", Helvetica, Arial, sans-serif;
    margin-left: auto;
    padding-left: 50px;
    max-width: 500px;

    @include media-breakpoint-down(lg) {
        padding-bottom: 30px;
        padding-left: 25px;
    }
}

.headerDesktop {
    display: block;
    margin-bottom: 60px;

    @include media-breakpoint-down(lg) {
        display: none;
    }
}

.logo {
    height: 40px;
    opacity: 0.8;
    text-align: right;
    margin-top: 50px;

    svg {
        height: inherit;
    }
}

.headerMobile {
    display: block;

    @include media-breakpoint-up(lg) {
        display: none;
    }
}

.linksContainer {
    font-weight: bolder;
    text-transform: uppercase;
    font-style: italic;
    font-size: 2.5rem;
    margin-top: 60px;
    margin-bottom: 24px;

    @include media-breakpoint-up(lg) {
        display: none;
    }
}

.link {
    text-decoration: none;
    color: #000;

    &:hover {
        color: #fff;
    }
}

.header {
    border-top: solid 2px #000;
    font-size: 3rem;
    font-weight: bold;
    text-transform: uppercase;
    letter-spacing: 0.2rem;
    font-style: italic;
    padding-top: 16px;
    margin-bottom: 8px;

    @include media-breakpoint-down(lg) {
        font-size: 2.5rem;
    }
}

.subHeader {
    width: 70%;
    font-size: 1rem;
    line-height: 1.5rem;

    @include media-breakpoint-down(lg) {
        font-size: 0.85rem;
        width: 100%;
    }
}

.contactForm {
    margin-top: 30px;

    :global {
        .form-label {
            font-weight: bold;
            text-transform: uppercase;
            font-size: 0.8rem;

            span {
                font-size: 0.7rem;
                opacity: 0.7;
                font-weight: 500;
            }

            @include media-breakpoint-down(lg) {
                font-size: 0.7rem;
            }
        }

        .form-control {
            height: 40px;
            margin-bottom: 10px;
            background-color: rgba(#fff, 0.8);

            &:focus {
                background-color: #fff;
            }

            @include media-breakpoint-down(lg) {
                height: 30px;
            }
        }

        textarea.form-control {
            min-height: 80px;
        }

        .btn {
            margin-top: 20px;
            font-family: "Kanit", "Open Sans", Helvetica, Arial, sans-serif;
            font-weight: 600;
            text-transform: uppercase;
            letter-spacing: 0.08rem;
            font-size: 0.9rem;
            padding: 0.6rem 2.4rem;
            color: #000;
            background: $blue-gradient;
            border: none;
            transform: skew(-10deg);
            border-radius: 0;
            border-bottom-right-radius: 10px;
        }
    }
}

.modalForm {
    padding-left: 0;
    margin: 0px auto;
    max-width: 90%;

    @include media-breakpoint-down(lg) {
        padding: 0px;
    }

    .header {
        margin-top: 10px;
    }

    .headerDesktop {
        display: none;
    }

    .contactForm {
        margin-top: 5px;

        :global(.btn) {
            margin-top: 5px;
        }
    }
}
