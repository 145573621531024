@import '../../layout/_vars';
@import '../../layout/_mixins';

.sectionWrapper {
    font-family: "Kanit", "Open Sans", Helvetica, Arial, sans-serif;
    color: #fff;
    margin: 120px 50px 50px;

    @media only screen and (max-width: 930px) {
        margin: 60px 0px 300px;
        padding: 0px 20px;
    }
}

.header {
    margin: auto;
    text-align: center;
    max-width: 650px;
    margin-bottom: 50px;

    @include media-breakpoint-down(sm) {
        padding: 0px 20px;
        margin-bottom: 20px;
    }

    h1 {
        font-size: 3rem;
        font-style: italic;
        font-weight: 600;
        text-transform: uppercase;
        background-image: $blue-gradient;
        background-clip: text;
        -webkit-text-fill-color: transparent;

        @include media-breakpoint-down(sm) {
            font-size: 2rem;
        }
    }

    p {
        font-size: 1rem;

        @include media-breakpoint-down(sm) {
            font-size: .86rem;
        }
    }
}

.carousel {
    height: 480px;

    @include media-breakpoint-down(md) {
        height: 760px;

    }

    @include media-breakpoint-down(xl) {
        height: 500px;
        :global {
            .carousel-indicators {
                display: none;
            }
        }
    }
}

.testimonial {
    width: 75vw;
    min-width: 290px;
    max-width: 1400px;
    padding: 30px 100px 50px;
    background-color: $light-grey;
    border: 1px solid $dark-grey;
    margin: auto;

    @include corner-stripes(100px);

    @include media-breakpoint-up(md) {
        padding: 30px 100px 50px !important;
    }

    @include media-breakpoint-down(md) {
        padding: 30px 50px 50px 75px;
    }

    @include media-breakpoint-down(sm) {
        width: 85vw;
    }

    .logoAndClientWrapper {
        display: flex;
        align-items: center;
        padding-bottom: 30px;

        @include media-breakpoint-down(sm) {
            padding-top: 50px;
            padding-bottom: 40px;
            align-items: flex-start;
            margin-left: -30px;
        }

        h1 {
            text-transform: uppercase;
            color: #fff;
            font-size: 2rem;

            @include media-breakpoint-down(sm) {
                font-size: 1.2rem !important;
            }
        }

        .company {
            font-size: .9rem;

            span {
                text-transform: none;
                border-left: 1px #fff solid;
                padding-left: 15px;
                margin-left: 10px;

                @include media-breakpoint-down(sm) {
                    width: 150px;
                    border: none;
                    margin: 0px;
                    padding: 0px;
                }
            }
        }

        .imageWrapper {
            width: 80px;
            height: 80px;
            margin-right: 20px;
            position: relative;

            @include media-breakpoint-down(sm) {
                aspect-ratio: 1;
                width: 50px;
                height: 50px;
            }

            :global {
                img {
                    border-radius: 100%;
                    width: 100%;
                    height: 100%;
                }
            }
        }
    }

    .quoteWrapper {
        font-size: .8rem;
        display: flex;
        justify-content: center;
        margin-left: -60px;
        font-style: italic;

        @include media-breakpoint-down(sm) {
            margin-left: -40px;
        }

        .quotationMarks {
            margin-top: 20px;
            padding-right: 10px;

            @include media-breakpoint-down(sm) {
                margin-top: 15px;
                padding-right: 0;
            }

            svg {
                transform: scale(.5);
            }
        }

        p {
            padding-top: 20px;
            font-size: 1.5rem;

            @include media-breakpoint-down(sm) {
                font-size: .9rem !important;
                margin-left: -5px;
            }
        }
    }
}
