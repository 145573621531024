@import '../../layout/_vars';
@import '../../layout/_mixins';

.pageFooter {
    background-color: #000;
    font-size: 80%;
    color: #fff;
    position: relative;

    .footerNav {
        background-color: $light-grey;
        padding: 30px 50px;

        @include media-breakpoint-down(md) {
            :global(.navbar) {
                justify-content: center;
            }

            :global(.navbar-brand) {
                margin-right: 0;
            }

            :global(.navbar-toggler) {
                display: none;
            }
        }

        @include media-breakpoint-down(sm) {
            padding: 15px 50px;
        }
    }

    .footerContent {
        flex-grow: 1;
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 40px;

        p {
            margin-bottom: 0;
        }

        @include media-breakpoint-down(sm) {
            flex-direction: column-reverse;
            background-color: #000;
            padding: 20px 40px;

            p {
                margin-top: 10px;
                font-family: "Kanit", "Open Sans", Helvetica, Arial, sans-serif;
                font-size: 12px;
                text-align: center;
            }
        }

        @include media-breakpoint-down(xs) {
            font-size: 0.85em;

            p {
                font-size: inherit;
            }

            :global(.nav-link) {
                padding-left: 0.5rem;
                padding-right: 0.5rem;
            }
        }
    }

    .socialLinks {
        justify-content: center;

        :global {
            .nav-item {
                margin: 0 15px;
            }

            .nav-link {
                margin: 0px;
            }
        }
    }
}
