@import '../../layout/_vars';
@import '../../layout/_mixins';

.sectionWrapper {
    font-family: "Kanit", "Open Sans", Helvetica, Arial, sans-serif;
    color: #fff;
    text-transform: uppercase;
    font-style: italic;
    font-weight: bold;
    position: relative;
    padding-left: 8vw;
    margin-top: 50px;
    z-index: 0;
    height: 780px;

    @include media-breakpoint-down(lg) {
        height: 500px;
        margin-top: 25px;
    }

    @include media-breakpoint-down(sm) {
        padding: 0px;
        height: auto;
    }
}

.background {
    position: absolute;
    top: 50px;
    left: 0;
    width: 100%;
    height: calc(100% - 80px);
    z-index: -1;
    background-image:
        linear-gradient(to right, rgba(#000, 0.66) 25%, transparent 70%),
        url("https://web.witcontests.com/cdn-img/quality=80/https://wit-web-assets.s3.amazonaws.com/website/Desktop_Hero_BackgroundImage_Full.jpg");
    background-repeat: no-repeat;
    background-attachment: fixed;
    background-position: right top 50px;
    background-size: cover;


    @include media-breakpoint-down(lg) {
        height: 500px;
        top: 25px;
        background-attachment: scroll;
        background-image:
            linear-gradient(to bottom, rgba(#000, 0.75), transparent 80%),
            url("https://web.witcontests.com/cdn-img/quality=80/https://wit-web-assets.s3.amazonaws.com/website/Desktop_Hero_BackgroundImage_Cropped.jpg");
        background-position: right top;
    }

    @include media-breakpoint-down(sm) {
        height: 350px;
        position: relative;
        margin-top: -50px;
        background-attachment: scroll;
        background-position: none;
        background-image:
            linear-gradient(to bottom, rgba(#000, 0.75), transparent 80%),
            url("https://web.witcontests.com/cdn-img/quality=80/https://wit-web-assets.s3.amazonaws.com/website/Desktop_Hero_BackgroundImage_Cropped.jpg");
        }
}

.background::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-image: url("https://wit-web-assets.s3.amazonaws.com/website/Desktop_Hero_BackgroundCornerGraphic.png");
    background-repeat: no-repeat;
    background-attachment: absolute;
    background-size: 20% auto;
    background-position: right bottom;
    z-index: -1;
}

.winWithWit {
    width: 725px;
    max-width: 80vw;

    @include media-breakpoint-down(md) {
        width: 525px;
    }

    @include media-breakpoint-down(sm) {
        width: 250px;
        margin-left: auto;
        margin-right: auto;
    }
}

.mainCopyWrapper {
    margin-top: 50.5px;
    display: flex;
    position: relative;
    padding-right: 30px;

    @include media-breakpoint-down(sm) {
        padding: 0px 10px 0 40px;
        margin-top: 50px;
        justify-content: center;
        flex-direction: column;
        max-width: 80%;
    }

    .mainLogosWrapper {
        margin-right: 25px;
        min-width: 180px;

        svg {
            width: 50px;
            height: 50px;
            margin: 0px 5px !important;
        }

        img {
            padding: 0px 5px !important;
        }
    }

    .description {
        font-size: 1.2rem;
        line-height: 1.75rem;
        font-weight: 400;
        font-style: normal;
        text-transform: none;
        width: 400px;
        color: #fff;

        @include media-breakpoint-down(sm) {
            margin: 20px 10px 20px 50px;
            font-weight: lighter;
            width: 100%;
        }
    }
}

.decorativeLineVertical {
    border-left: 1px solid #e6f75f;
    position: absolute;
    height: 620px;
    left: calc((5px * 3) + 50px + 25px);
    top: 50px;

    @include media-breakpoint-down(sm) {
        left: calc(30px + (5px * 3) + 25px);
        height: 505px;
    }
}

.decorativeLineHorizontal {
    border-bottom: 1px solid #e6f75f;
    width: calc(92vw - 108px);
    height: 0;
    bottom: 0;
    left: 0;
    position: absolute;

    @include media-breakpoint-down(sm) {
        width: calc(100vw - 88px);
    }
}
