@import '../../layout/_vars';
@import '../../layout/_mixins';

.sectionWrapper {
    font-family: "Kanit", "Open Sans", Helvetica, Arial, sans-serif;
    color: #fff;
}

.mainHeader {
    margin: auto;
    text-align: center;
    max-width: 650px;
    margin-bottom: 60px;

    @include media-breakpoint-down(sm) {
        padding: 0px 20px;
        margin-bottom: 20px;
    }

    h1 {
        font-size: 3rem;
        font-style: italic;
        font-weight: 600;
        text-transform: uppercase;
        background-image: $blue-gradient;
        background-clip: text;
        -webkit-text-fill-color: transparent;

        @include media-breakpoint-down(sm) {
            font-size: 1.8rem;
        }
    }

    p {
        font-size: 1.3rem;

        @include media-breakpoint-down(sm) {
            font-size: .9rem;
        }
    }
}

.services {
    justify-content: center;
    max-width: 1450px;
    margin: 0 auto;

    @include media-breakpoint-down(sm) {
        display: block;
        padding: 0px 20px;
    }
}

.serviceWrapper {
    position: relative;
    margin: 0 auto 50px;
    max-width: 500px;
    background-repeat: no-repeat;

    &::before {
        content: '';
        position: absolute;
        border-left: 1px solid #e6f75f;
        height: 100%;
        left: 20px;
        top: 1px;
        z-index: 1;
    }

    .serviceImage {
        width: 100%;
        aspect-ratio: 5/3;
        position: relative;

        // Hide image edges on Webkit
        img {
            border: 1px solid $dark-grey !important;
        }

        @include corner-stripes(150px);

        @include media-breakpoint-down(lg) {
            @include corner-stripes(75px);
        }
    }

    .contentWrapper {
        margin-left: 20px;
        padding-top: 20px;
        padding-left: 27.5px;
        padding-bottom: 27.5px;

        .header {
            padding: 0px 16px;
            font-size: 1.6rem;
            background-image: $blue-gradient;
            background-clip: text;
            -webkit-text-fill-color: transparent;
            text-transform: uppercase;
            font-style: italic;
            font-weight: bold;

            @include media-breakpoint-down(sm) {
                font-size: 1.8rem;
            }
        }

        .text {
            padding: 0px 16px;
            max-width: 350px;
            font-size: 1rem;
            line-height: 1.4rem;

            @include media-breakpoint-down(sm) {
                font-size: .8rem;
            }
        }
    }
}

.banner {
    margin-top: 88px;
    margin-right: 110px;
    position: relative;

    @include media-breakpoint-down(lg) {
        margin-top: 40px;
        margin-right: 40px;
    }
}
