@import '../../layout/_vars';
@import '../../layout/_mixins';

.sectionWrapper {
    font-family: "Kanit", "Open Sans", Helvetica, Arial, sans-serif;
    color: #fff;
    text-transform: uppercase;
    font-style: italic;
    font-weight: bold;
    margin-top: 80px;
}

.caseStudyWrapper {
    padding: 0px 80px 0px;
    display: flex;
    justify-content: center;
    position: relative;

    &::before {
        content: '';
        position: absolute;
        border-top: 1px #E6F75F solid;
        top: 50px;
        width: 100%;
    }

    @include media-breakpoint-down(lg) {
        display: block;
        padding: 0;
    }

    .imageWrapper {
        min-width: 320px;
        width: 75vw;
        max-width: 800px;
        height: 100%;
        position: relative;
        justify-content: center;
        display: flex;

        @include media-breakpoint-down(lg) {
            transform: scale(.9);
            margin: 0 auto;
        }

        .computerScreen {
            width: 95%;
            height: 70%;
            position: absolute;
            top: 5%;
        }

        svg {
            width: 100%;
        }
    }

    .caseStudyCopy {
        margin-top: 60px;
        max-width: 500px;
        margin-left: 50px;

        @include media-breakpoint-down(lg) {
            margin: 30px auto;
            padding: 0px 50px;
            max-width: 600px;
        }

        h1 {
            font-size: 3rem;
            background-image: $blue-gradient;
            background-clip: text;
            -webkit-text-fill-color: transparent;
            margin: 0;
            line-height: 2.9rem;
            font-weight: bold;

            @include media-breakpoint-down(sm) {
                font-size: 2.5rem;
            }
        }

        h2 {
            font-size: 1.3rem;
            margin: 0;

            @include media-breakpoint-down(sm) {
                font-size: 1rem;
            }
        }

        h3 {
            margin-top: 10px;
            margin-bottom: 0;
            font-size: .8rem;

            @include media-breakpoint-down(sm) {
                font-size: .7rem;
            }
        }

        p {
            font-size: .95rem;
            font-style: normal;
            font-weight: normal;
            text-transform: none;
            margin-top: 20px;

            @include media-breakpoint-down(sm) {
                font-size: .9rem;
            }

            span {
                text-transform: uppercase;
                a {
                    text-decoration: none;
                    color: #E6F75F;
                }
            }
        }
    }
}

.caseStudyBanner {
    margin: 88px 110px 88px 0px;
    padding-right: 100px;
    position: relative;
    background-color: $light-grey;
    display: flex;
    justify-content: space-around;
    align-items: baseline;
    flex-wrap: wrap;

    @include corner-stripes(200px);

    @include media-breakpoint-down(lg) {
        margin-right: 40px;
    }

    @include media-breakpoint-down(md) {
        margin: 88px 20px;
    }

    @include media-breakpoint-down(sm) {
        flex-direction: column;
        justify-content: center;
        align-items: center;
        padding: 0 0 80px 0;

        @include corner-stripes(150px);
    }
}

.caseStudyBannerItem {
    margin: 30px 30px 45px 30px;
    text-align: center;

    .itemValueWrapper {
        color: #E6F75F;
        font-weight: 800;
        font-style: italic;
    }

    .itemValuePrefix {
        font-size: 2.5rem;
        vertical-align: 2rem;
    }

    .itemValue {
        font-size: 3.5rem;
        line-height: 4rem;
    }

    .itemTitle {
        margin-top: 5px;
        margin-bottom: 0;
    }

    @include media-breakpoint-down(sm) {
        flex-basis: 100%;
        margin: 20px 30px;

        .itemValue {
            font-size: 3rem;
            line-height: 5rem;
        }

        .itemValuePrefix {
            font-size: 1rem;
        }
    }
}
