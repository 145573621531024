@import "../../layout/_vars";
@import "../../layout/_mixins";

.navWrapper {
    text-transform: uppercase;
    font-family: "Kanit", "Open Sans", Helvetica, Arial, sans-serif;
    letter-spacing: 0.2rem;
    font-size: 0.9rem;
    background-color: $dark-grey;
    color: #fff;
    padding: 50px;

    @include media-breakpoint-down(md) {
        padding: 20px 30px;
    }
}

.navBar {
    justify-content: flex-end;
}

.navBarLinksContainer {
    display: flex;
    flex-direction: column;
    margin-top: 8px;

    @include media-breakpoint-up(lg) {
        flex-direction: row;
        width: unset;
        margin-top: 0px;
    }
}

.button {
    color: #fff;
    margin-left: 30px;
    padding: 0.6rem 2.4rem;
    font-weight: 600;
    text-transform: uppercase;
    letter-spacing: 0.05rem;
    font-size: inherit;
    border: none;
    transform: skew(-10deg);
    text-decoration: none;
    margin-top: 8px;

    &:hover {
        color: #777;
    }
}

.contactButton {
    color: #000;
    background: $blue-gradient;
    border-radius: 1px;
    border-bottom-right-radius: 10px;

    &:hover {
        color: #fff;
    }
}

.navbarToggle {
    border: 0;

    :global(.navbar-toggler-icon) {
        background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='30' height='30' viewBox='0 0 30 30'%3e%3cpath stroke='rgba(3, 255, 255, 0.5)' stroke-linecap='round' stroke-miterlimit='10' stroke-width='2' d='M4 7h22M4 15h22M4 23h22'/%3e%3c/svg%3e");
    }
}

.navLogoWrapper {
    width: auto;
    height: 55px;
    opacity: 0.5;

    @include media-breakpoint-down(md) {
        height: 40px;
    }

    :global(.navbar-brand) {
        width: auto;
        height: inherit;
        display: block;
    }

    svg {
        height: 100%;
    }
}
