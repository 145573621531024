@import '../../layout/_vars';
@import '../../layout/_mixins';

$decorative-line-top: 70px;

.sectionWrapper {
    font-family: "Kanit", "Open Sans", Helvetica, Arial, sans-serif;
    color: #fff;
    margin: 80px 0 0 70px;
    padding: 0 25px 100px 70px;
    position: relative;
    z-index: 0;

    h1 {
        font-size: 3rem;
        font-style: italic;
        font-weight: 600;
        text-transform: uppercase;
        background-image: $blue-gradient;
        background-clip: text;
        -webkit-text-fill-color: transparent;

        @include media-breakpoint-down(sm) {
            font-size: 2rem;
        }
    }

    @include media-breakpoint-down(lg) {
        padding-left: 40px;
        padding-bottom: 70px;
        margin-left: 20px;
        background: none;
    }

    @include media-breakpoint-down(sm) {
        padding: 0 0 40px 0;
        margin: 20px 0 0 0;
        background: none;
    }
}

.background {
    z-index: -1;
    position: absolute;
    top: 200px;
    bottom: 0;
    left: 0;
    background-color: $light-grey;
    width: 100%;

    @include corner-stripes(200px, 0%, 0%, 180);
}

.activationExamples .copy,
.brandExamples {
    @include media-breakpoint-down(lg) {
        padding: 0 40px;
    }

    @include media-breakpoint-down(sm) {
        padding-right: 20px;
    }
}

.copy {
    max-width: 650px;
    flex-shrink: 1;

    p {
        max-width: 400px;
        font-size: 1.2rem;
        line-height: 1.75;
    }

    @include media-breakpoint-down(md) {
        h1 {
            font-size: 2.5rem;
        }

        p {
            font-size: .86rem;
        }
    }
}

.activationExamples {
    display: flex;
    align-items: flex-start;
    margin: auto;
    margin-left: -50px;
    width: 100%;

    @include media-breakpoint-down(lg) {
        flex-direction: column;
        margin-left: 0;
    }

    .phonesWrapper {
        margin: 0;
        height: 600px;
        display: flex;

        @include media-breakpoint-down(xl) {
            height: 450px;
        }

        @include media-breakpoint-down(lg) {
            margin-left: 30px;
            align-self: stretch;
        }

        @include media-breakpoint-down(md) {
            height: 400px;
            margin-left: 0;
        }

        @include media-breakpoint-down(sm) {
            margin-left: 15px;
            height: 330px;
            justify-content: flex-start;
        }

        @media (max-width: 400px) {
            margin: 0 auto;
        }
    }

    .phoneImageWrapper {
        height: 100%;
        max-height: 100%;
        aspect-ratio: 0.5;
        position: relative;
        justify-content: center;
        display: flex;
        align-items: center;
        margin: 0px 20px;

        @include media-breakpoint-down(sm) {
            height: 300px;
            width: 150px;
            margin: 0 3vw;
        }

        svg {
            position: absolute;
            max-height: inherit;
        }

        .phoneScreen {
            width: 92.5%;
            height: 100%;
            max-height: inherit;
            position: relative;
        }
    }

    .copy {
        margin-top: $decorative-line-top + 20px;
        margin-left: 50px;

        @include media-breakpoint-down(lg) {
            margin-top: 30px;
            margin-left: 0;
        }

        h1 {
            padding-bottom: 20px;
        }

        p {
            margin-top: 25px;
        }


        @include media-breakpoint-down(sm) {
            h1 {
                padding-bottom: 15px;
                font-size: 2.5rem;
            }

            p {
                font-size: .86rem;
            }
        }
    }
}

.brandExamples {
    display: flex;
    margin-top: 80px;
    align-items: center;
    padding-left: 150px;

    @media only screen and (max-width: 1555px) {
        padding-left: 45px;
    }

    @include media-breakpoint-down(lg) {
        flex-direction: column;
        align-items: flex-start;
        margin-top: 40px;
    }

    .copy {
        margin-right: 20px;

        @include media-breakpoint-down(md) {
            margin-right: 0;
        }

        h1 {
            max-width: 250px;
        }

        p {
            max-width: 400px;

            @media only screen and (max-width: 1399px) {
                width: 300px;
            }

            @media only screen and (max-width: 1000px)  {
                width: 400px;
            }

            @include media-breakpoint-down(sm)  {
                max-width: 300px;
            }
        }
    }

    .brandLogos {
        display: flex;
        justify-content: space-around;
        flex-wrap: wrap;
        flex-grow: 2;
        max-width: 900px;
        margin: 20px 35px;
        padding: 0px;

        @include media-breakpoint-down(md) {
            margin: 20px -10px;
            aspect-ratio: 1;
            max-width: 100%;
        }
    }
}

.banner {
    position: absolute;
    bottom: 0;
    left: 40px;
    width: calc(100% - 40px);
    transform: translateY(50%);
    text-align: right;
}

.decorativeLineHorizontal {
    border-top: 1px solid #e6f75f;
    position: absolute;
    width: calc(100% - 140px);
    left: 140px;
    top: $decorative-line-top;

    @include media-breakpoint-down(md) {
        width: calc(100% - 60px);
        left: 60px;
        top: 330px;
    }
}
