@import '../../layout/_vars';
@import '../../layout/_mixins';

.sectionWrapper {
    font-family: "Kanit", "Open Sans", Helvetica, Arial, sans-serif;
    color: #fff;
    text-transform: uppercase;
    font-style: italic;
    font-weight: bold;
    padding: 0px 0px 100px 120px;
    position: relative;
    z-index: 0;

    @include media-breakpoint-down(lg) {
        padding-left: 20px;
    }

    @include media-breakpoint-down(md) {
        padding-bottom: 0;
    }

    @include media-breakpoint-down(sm) {
        svg {
            margin-top: -20px;
            transform: scale(.5);
        }
    }
}

.background {
    position: absolute;
    z-index: -1;
    background-image: url('https://web.witcontests.com/cdn-img/quality=80/https://wit-web-assets.s3.amazonaws.com/website/contact_us.jpg');
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    top: 150px;
    left: 20%;
    bottom: 0;
    width: 80%;

    @include corner-stripes(275px, -0.1%, -0.1%, 180);

    @include media-breakpoint-down(md) {
        position: static;
        height: 400px;
        width: 100%;
        margin-top: 40px;

        @include corner-stripes(150px, 0%, 0%, 180);
    }
}

.line {
    margin-left: 40px;
    height: 240px;
    border-left: 1px solid #e6f75f;

    @include media-breakpoint-down(lg) {
        height: 50px;
    }
}

.header {
    font-size: 6rem;
    line-height: 6.5rem;
    max-width: 50vw;

    span {
        margin-left: 3rem;
        font-size: 13rem;
        background-image: $blue-gradient;
        background-clip: text;
        -webkit-text-fill-color: transparent;
    }

    @include media-breakpoint-down(lg) {
        font-size: 4rem;
        line-height: 5rem;
        padding: 0px 20px;
        margin-left: 1.5rem;

        span {
            text-align: center;
            font-size: 10rem;
            margin-left: 24px;
        }
    }

    @include media-breakpoint-down(sm) {
        font-size: 3rem;
        line-height: 3.5rem;
        padding: 0px 20px;
        margin-left: 1.5rem;

        span {
            text-align: center;
            font-size: 6rem;
            margin-left: 24px;
        }
    }
}

.description {
    font-style: normal;
    font-weight: normal;
    text-transform: none;
    font-size: 1rem;
    margin-top: 42px;
    max-width: 330px;

    @include media-breakpoint-down(lg) {
        margin-top: 20px;
        margin-left: 40px;
    }

    @include media-breakpoint-down(sm) {
        width: 75%;
        margin-left: 60px;
        margin-top: 10px;
        font-size: .8rem;
    }

    .contactButton {
        color: #000;
        padding: 0.6rem 2.4rem;
        font-weight: 600;
        text-transform: uppercase;
        letter-spacing: 0.05rem;
        font-size: 0.9rem;
        background: $blue-gradient;
        border: none;
        transform: skew(-10deg);
        border-radius: 1px;
        border-bottom-right-radius: 10px;
    }
}
