@import "../../layout/_vars";
@import "../../layout/_mixins";

.pageWrapper {
    background-color: $dark-grey;
    min-height: 100vh;
    min-width: 320px;
    overflow-y: auto;
    position: relative;
}

.offCanvas {
    background-image: $blue-gradient;
    background-repeat: no-repeat;
    background-color: $dark-blue;
    display: block;
    height: 100vh;
    width: 500px !important;
    border-left: 0px !important;
    overflow-y: auto;

    @include corner-stripes(200px, 0%, 0%, 180);

    @include media-breakpoint-down(sm) {
        @include corner-stripes(100px, 0%, 0%, 180);
        width: 100vw !important;
    }
}

.offCanvasHeader {
    align-items: start;

    @include media-breakpoint-down(sm) {
        padding: 24px 24px 0px 24px;
    }
}
