$theme-colors: (
    'primary': #4A37CA
);

@import '~bootstrap/scss/functions';
@import '~bootstrap/scss/variables';
@import '~bootstrap/scss/mixins';

$light-grey: #313637;
$dark-grey: #1a1a1a;
$light-blue: #03ffff;
$dark-blue: #2d8aea;
$blue-gradient: linear-gradient(to left, $dark-blue, $light-blue);
