@use 'sass:math';

@mixin corner-stripes($size: 100px, $position-x: 100%, $position-y: 100%, $rotation: 0) {
    mask-border: initial;
    mask-image: linear-gradient(#000 0 0),
    url("../../public/assets/corner_stripes_#{$rotation}.svg"),
    linear-gradient(#000 0 0);
    mask-mode: alpha, alpha, alpha;
    mask-position: $position-x math.abs($position-y - 100%), $position-x $position-y, 0 0;
    mask-repeat: no-repeat, no-repeat, no-repeat;
    mask-composite: exclude, exclude, exclude;
    mask-size: math.div($size, 10.5) calc(100% - $size + ($size * .0905)), $size, 100%;
}

@mixin aspect-ratio($width, $height) {
    position: relative;

    &:before {
        display: block;
        content: "";
        width: 100%;
        padding-top: ($height / $width) * 100%;
    }

    >.content {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
    }
}

// The following three functions are written by codepen.io/jabok-e
// https://codepen.io/jakob-e/pen/doMoML

//  Function to create an optimized svg url
@function svg-url($svg){

//  Add missing namespace
@if not str-index($svg, xmlns) {
    $svg: str-replace($svg, '<svg', '<svg xmlns="http://www.w3.org/2000/svg"');
}

//  Chunk up string in order to avoid
//  "stack level too deep" error
$encoded: '';
$slice: 2000;
$index: 0;
$loops: ceil(str-length($svg)/$slice);

@for $i from 1 through $loops {
    $chunk: str-slice($svg, $index, $index + $slice - 1);
    //   Encode
    $chunk: str-replace($chunk, '"', '\'');
    $chunk: str-replace($chunk, '%', '%25');
    $chunk: str-replace($chunk, '&', '%26');
    $chunk: str-replace($chunk, '#', '%23');
    $chunk: str-replace($chunk, '{', '%7B');
    $chunk: str-replace($chunk, '}', '%7D');
    $chunk: str-replace($chunk, '<', '%3C');
    $chunk: str-replace($chunk, '>', '%3E');

    //    The maybe list
    //
    //    Keep size and compile time down
    //    ... only add on documented fail
    //
    //  $chunk: str-replace($chunk, '|', '%7C');
    //  $chunk: str-replace($chunk, '[', '%5B');
    //  $chunk: str-replace($chunk, ']', '%5D');
    //  $chunk: str-replace($chunk, '^', '%5E');
    //  $chunk: str-replace($chunk, '`', '%60');
    //  $chunk: str-replace($chunk, ';', '%3B');
    //  $chunk: str-replace($chunk, '?', '%3F');
    //  $chunk: str-replace($chunk, ':', '%3A');
    //  $chunk: str-replace($chunk, '@', '%40');
    //  $chunk: str-replace($chunk, '=', '%3D');

    $encoded: #{$encoded}#{$chunk};
    $index: $index +$slice;
}

@return url("data:image/svg+xml,#{$encoded}");
}

//  Background svg mixin
@mixin background-svg($svg) {
    background-image: svg-url($svg);
}

//  Helper function to replace characters in a string
@function str-replace($string, $search, $replace: '') {
    $index: str-index($string, $search);
    @return if($index,
        str-slice($string, 1, $index - 1) + $replace + str-replace(str-slice($string, $index + str-length($search)), $search, $replace),
        $string);
}
